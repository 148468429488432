import React from "react";
import {
  StyledIconsSlider,
  StyledIconsSliderItem,
  StyledIconsSliderItemIcon,
  StyledIconsSliderItemText,
  StyledIconsSliderItems,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";

export default ({ items }) => {
  return (
    <StyledIconsSlider>
      <StyledGridRow isFullMobile noMargin>
        <Scroller>
          <StyledIconsSliderItems>
            {items.map((item, index) => (
              <StyledIconsSliderItem key={index}>
                <StyledIconsSliderItemIcon bg={item.icon} />
                <StyledIconsSliderItemText
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </StyledIconsSliderItem>
            ))}
          </StyledIconsSliderItems>
        </Scroller>
      </StyledGridRow>
    </StyledIconsSlider>
  );
};
